import { default as indexmFsYN4t0XfMeta } from "/opt/build/repo/pages/blog/index.vue?macro=true";
import { default as indexahrOYhTEvHMeta } from "/opt/build/repo/pages/cra-express/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as indexlWyU3b38IQMeta } from "/opt/build/repo/pages/legal/index.vue?macro=true";
import { default as indexZGHdGUcL1pMeta } from "/opt/build/repo/pages/rgpd/index.vue?macro=true";
export default [
  {
    name: "blog",
    path: "/blog",
    meta: indexmFsYN4t0XfMeta || {},
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "cra-express",
    path: "/cra-express",
    component: () => import("/opt/build/repo/pages/cra-express/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "legal",
    path: "/legal",
    meta: indexlWyU3b38IQMeta || {},
    component: () => import("/opt/build/repo/pages/legal/index.vue").then(m => m.default || m)
  },
  {
    name: "rgpd",
    path: "/rgpd",
    meta: indexZGHdGUcL1pMeta || {},
    component: () => import("/opt/build/repo/pages/rgpd/index.vue").then(m => m.default || m)
  }
]